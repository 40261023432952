.offers {
  width: 100%;
  padding: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.offers svg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  fill: #f8f8f8;
}
.offers .offers-container img {
  width: 480px;
  max-width: 100%;
  height: auto;
}
.offers-all-text {
  width: min-content;
  min-width: 240px;
  padding: 0 10px;
}
.offres-block {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  padding: 50px;
  z-index: 1;
  text-align: center;
}
.header-offre {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.left-offre img {
  width: 300px;
}
.header-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: large;
  font-weight: 500;
}
.content-offre {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 72px;
  font-weight: bolder;
  background: -webkit-linear-gradient(-45deg, #ff3636, #fe75f7);
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: transparent;
  font-family: none;
  font-size: 32px;
  letter-spacing: 2px;
  margin-top: 40px;
}
.right-offre h1 {
  font-size: 72px;
  font-weight: bolder;
  background: -webkit-linear-gradient(-45deg, #ff3636, #ff4af5);
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: transparent;
  font-family: none;
}
.offers-container,
.offers-container-seconde {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgb(0, 0, 0);
  padding: 50px;
  margin-bottom: 20px;
}
.offers-container-seconde {
  margin-bottom: 10px;
}
.offers-all-text h1,
.offres-block > h1 {
  font-size: 35px;
  color: #111111;
  font-weight: 700;
  margin-bottom: 20px;
}
.offers-all-text p {
  font-size: 16px;
  color: #777777;
  line-height: 30px;
  margin-bottom: 35px;
}

.btn-offers .svg {
  box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 6%);
}

.offers .react-share__ShareButton svg,
.offers .copy svg {
  position: relative;
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
  fill: blue;
  margin: 5px;
}
.offers .copy svg {
  fill: rgba(0, 0, 0, 0.5);
}

.react-share__ShareButton:focus-visible,
.share-facebook:focus-visible,
.react-share__ShareButton,
.share-facebook {
  outline: none;
}
.share-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: x-large;
  font-weight: 500;
}
.share-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.offres-img-seconde {
  width: 50%;
}

.offer-section.offer-section2 {
  background: #ffffff;
}

.offer-section.offer-section2 .offer-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 200px;
  align-items: center;
  padding: 0 80px;
}

.offer-section.offer-section2 .offer-container .offer-text p {
  font-size: x-large;
  margin-bottom: 20px;
}

.offer-section.offer-section2 .offer-container .offer-image img {
  max-width: 400px;
}

.offer-section.offer-section2 .offer-container .offer-image {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}
.offer-section.offer-section2 .offer-container .offer-image img {
  animation: none;
}

@media screen and (max-width: 1050px) {
  .offers .offers-container img {
    width: 380px;
  }

  .offer-section.offer-section2 .offer-container {
    gap: 20px;
    padding: unset;
  }
}

@media screen and (max-width: 950px) {
  .offers {
    padding: 40px 15px;
  }
  .offers-container {
    padding: 14px;
  }
  .offers-all-text {
    min-width: 50%;
    padding: 0 0 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .offres-img-seconde {
    width: -webkit-fill-available;
  }
  .offres-block {
    padding: 20px;
  }
  .left-offre img {
    width: 200px;
  }
  .content-offre {
    font-size: 20px;
  }
  .header-text {
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 625px) {
  .offer-section.offer-section2 .offer-container .offer-text p {
    font-size: large;
  }

  .offers-all-text h1,
  .offres-block > h1 {
    font-size: 25px;
  }
  .offers-all-text p {
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
  .offers {
    padding: 40px 15px;
  }
}

@media screen and (max-width: 425px) {
  .offers-container-seconde {
    padding: 10px;
  }

  .offer-section.offer-section2 .offer-container .offer-text p {
    font-size: medium;
  }

  .left-offre img {
    width: 160px;
  }
  .header-text {
    font-size: 12px;
  }
  .right-offre h1 {
    font-size: 60px;
  }
  .content-offre {
    font-size: 17px;
  }
  .share-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: large;
    font-weight: 400;
  }
  .offers .react-share__ShareButton svg {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 320px) {
  .offres-block {
    padding: 10px;
  }
  .offers {
    padding: 40px 10px;
  }
}

/* Responsive */

@media (max-width: 1024px) {
  .offer-container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .offer-text h2 {
    font-size: 1.5rem;
  }

  .offer-text p {
    font-size: 0.9rem;
  }
}
