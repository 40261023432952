nav .nav-link {
  font-size: 14px;
  letter-spacing: 2px;
  padding: 0;
}

.nav-link-navbar {
  display: none;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 0;
  color: #fff;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
}

.nav-link-navbar:hover {
  color: #000000;
  text-decoration: none;
  border: 1px solid rgb(0, 0, 0);
}

.navbar-nav .nav-item {
  margin-right: 20px;
}

.navbar-collapse .form-select {
  width: 80px;
  margin-right: 10px;
}

select.form-select {
  border: unset;
}

.navbar {
  background-color: rgb(255 241 223) !important;
}

.navbar-brand {
  background-image: url("../../../../public/images/logo.png") !important;
  background-repeat: no-repeat !important;
  background-size: contain;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
  /* box-shadow: 1px 2px 3px black; */
}

.language-container {
  display: flex;
  align-items: center;
}

.language-item {
  color: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  cursor: pointer;
}

.language-item.active {
  color: rgba(0, 0, 0, 1);
}

.language-item-divider {
  color: rgba(0, 0, 0, 0.5);
}

:root {
  --mainColor: rgb(255 241 223);
  --secondaryColor: #000000;
  --textColor: rgba(0, 0, 0, 0.5);
}

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  z-index: 999;
  width: 100%;
  padding: 0 2rem;
  background-color: #97afbc;
  color: var(--textColor);
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 48%);
}

nav a {
  margin: 0 1rem;
  color: #fff;
  text-decoration: none;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

header h3 {
  margin: 0;
}

@media only screen and (max-width: 1100px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  .nav-link-navbar {
    display: unset;
  }

  header nav {
    position: fixed;
    top: -130vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #97afbc;
    transition: 1s;
  }

  header .responsive_nav {
    text-align: center;
    transform: translateY(130vh);
    z-index: 999;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 425px) {
  nav a {
    font-size: 1rem;
  }
}

/* Promotion Button */
.promotion-button {
  /* background: linear-gradient(45deg, #ff416c, #ff4b2b);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
  animation: floatingButton 3s ease-in-out infinite;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover State */
/* .promotion-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  text-decoration: none;
} */

/* Floating Animation */
@keyframes floatingButton {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
