.contact {
  padding: 50px;
  text-align: center;
  background-color: #97afbc;
}
.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: start;
}
.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.contact-container a.contact-info:hover {
  text-decoration: none;
  color: black;
}
.contact-text {
  margin-left: 10px;
  width: 260px;
}
.contact-info svg {
  fill: rgb(255, 255, 255);
}
.contact-content-seconde {
  width: 50%;
  height: 400px;
}

.services-logo {
  display: flex;
  background-image: url("../../../../public/images/logo.png") !important;
  background-repeat: no-repeat !important;
  background-size: contain;
  background-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.contact-info-text {
  text-align: start;
  margin: 0;
  padding-left: 20px;
}
.contact-info-text h1 {
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  text-shadow: 3px 3px 3px black;
}
.contact-info-text p {
  font-size: 18px;
  font-weight: 700;
  text-align: end;
  text-shadow: 3px 3px 3px black;
}

.share-page-title {
  color: #fff;
}

.share-page-container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-page-title,
.share-page-facebook {
  margin-right: 10px;
}

.share-page-facebook svg,
.page-copy svg {
  width: 30px;
}
.react-share__ShareButton:focus-visible,
.share-page-facebook:focus-visible,
.react-share__ShareButton,
.share-page-facebook,
button:focus {
  outline: none;
}

@media screen and (max-width: 700px) {
  .contact-info-text h1 {
    font-size: 20px;
  }
  .contact-info-text p {
    font-size: 11px;
    margin-right: -16px;
  }
  .services-logo {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 765px) {
  .contact-content-seconde {
    width: 100%;
    height: 300px;
    padding: 10px;
  }
  .contact {
    padding: 20px;
  }
}

@media screen and (max-width: 390px) {
  .contact-info-text h1 {
    font-size: 15px;
  }
  .contact-info-text p {
    font-size: 9px;
    margin-right: -16px;
  }
}

@media screen and (max-width: 310px) {
  .contact-info-text h1 {
    font-size: 11px;
  }
  .contact-info-text p {
    font-size: 7px;
    margin-right: -15px;
  }

  .contact-content {
    width: -webkit-fill-available;
    font-size: small;
  }
}
