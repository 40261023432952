.services {
  width: 100%;
  padding: 30px 50px;
  background: #97afbc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services .services-main img {
  width: 500px;
  max-height: 500px;
  object-fit: contain;
  max-width: 100%;
  height: auto;
  padding: 10px;
}
.services-all-text {
  width: 600px;
  max-width: 100%;
  padding: 0 10px;
}
.services-main {
  background-color: white;
  padding: 20px;
  width: 1290px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #eee;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.services-all-text h4 {
  font-size: 17px;
  color: #777777;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 10px;
}
.services-all-text h1 {
  font-size: 35px;
  color: #111111;
  font-weight: 700;
  margin-bottom: 20px;
}
.services-all-text p {
  font-size: 16px;
  color: #777777;
  line-height: 30px;
  margin-bottom: 35px;
}

.services-logo {
  display: flex;
  background-image: url("../../../../public/images/logo.png") !important;
  background-repeat: no-repeat !important;
  background-size: contain;
  background-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* box-shadow: 1px 2px 3px black; */
}
.services-container {
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0 20px 0;
}

.services-container svg {
  position: absolute;
  top: 0;
  left: 0;
}
.services-container h1,
.services-container a {
  z-index: 1;
}

.services-title-logo {
  font-size: 40px;
  font-weight: 700;
}

.hidden-desktop {
  display: none;
}
.promo-price {
  color: red;
}
@media screen and (max-width: 1200px) {
  .hidden-desktop {
    display: unset;
  }
  .hidden-mobile {
    display: none;
  }
  .services-all-text h4 {
    font-size: 15px;
  }
}
@media screen and (max-width: 1250px) {
  .services {
    height: auto;
    padding: 30px 0;
  }
  .services-all-text {
    text-align: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 625px) {
  .services-all-text h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .services-logo {
    width: 70px;
    height: 70px;
  }
  .services-container {
    padding: 50px 0 20px 0;
  }
  .services-container h1 {
    font-size: 25px;
    color: #fff;
    text-shadow: 3px 3px 3px black;
  }
  .services-all-text h4 {
    font-size: 13px;
  }
}
