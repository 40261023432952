.about {
  width: 100%;
  padding: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about .main img {
  width: 580px;
  max-width: 100%;
  height: auto;
  padding: 0 10px;
  filter: brightness(100%) hue-rotate(0deg) saturate(54%) contrast(101%);
}
.all-text {
  width: 600px;
  max-width: 100%;
  padding: 0 10px;
  text-align: center;
}
.main {
  width: 1290px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.all-text h4 {
  font-size: 18px;
  color: #777777;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 10px;
}
.all-text h1 {
  font-size: 35px;
  color: #111111;
  font-weight: 700;
  margin-bottom: 20px;
}
.all-text p {
  font-size: 16px;
  color: #777777;
  line-height: 30px;
  margin-bottom: 35px;
}

.btn-about .svg {
  box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 6%);
}

@media screen and (max-width: 1250px) {
  .about {
    height: auto;
    padding: 60px 0;
  }
  .all-text {
    text-align: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 625px) {
  .about .main img {
    margin-bottom: 35px;
  }
  .all-text h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
