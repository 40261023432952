.form {
  width: 100%;
  padding: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.form svg {
  position: absolute;
  top: 0;
  left: 0;
  fill: rgb(255 241 223);
}

.form .form-main img {
  width: 580px;
  max-width: 100%;
  height: auto;
  padding: 0 10px;
  border-radius: 100px;
}
.form-container {
  width: 600px;
  max-width: 100%;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form-main {
  width: 1290px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
}

.form-control {
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-datepicker-wrapper {
  width: 100%;
}

.form form input,
.form form select {
  height: 56px !important;
  border: 1px solid #1e1e1e;
  color: #6c757d;
  border-radius: 0;
  /* padding: 22px 24px; */
  margin-bottom: 20px;
}
.form form {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
}

.form-block {
  width: 100%;
}

.form-container h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-transform: uppercase;
}

.form-container span {
  margin: 24px 0 8px;
  font-size: 16px;
  line-height: 20px;
  max-width: 394px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
  display: flex;
  margin-top: -16px;
}

@media screen and (max-width: 1250px) {
  .form {
    height: auto;
    padding: 0 0 20px 0;
  }
  .form-container {
    text-align: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 625px) {
  .form .form-main img {
    margin-bottom: 35px;
  }
  .form-container h2 {
    font-size: 20px;
  }
}

.form-control:disabled {
  background-color: white !important;
}
