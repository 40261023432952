.custom-btn {
  padding: 10px 30px;
  border: 1px solid black;
  text-align: center;
  display: inline-block;
  width: max-content;
  cursor: pointer;
  font-weight: 300;
  font-size: large;
  color: black;
  background: transparent;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.light.custom-btn {
  color: #fff;
  border: 1px solid rgb(255, 255, 255);
}

.custom-btn:hover {
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  text-decoration: none;
}

.custom-disableBtn {
  display: none;
}

@media screen and (max-width: 340px) {
  .custom-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 300px) {
  .custom-btn {
    font-size: 12px;
    padding: 10px 20px;
  }
}

.popup {
  bottom: 0;
  width: 80%;
  height: 70%;
  transform: translate(12%, 18%);
  background-color: white;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
}

.popup-close-btn {
  display: flex;
  align-items: center;
  justify-content: end;
}

.popup-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* //////////////////////////////// */

.no-scroll {
  overflow: hidden;
}

.popup-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.popup-form {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 900px;
  border-radius: 10px;
  overflow: hidden;
}

.popup-form-left,
.popup-form-right {
  padding: 20px;
  flex: 1;
}

.popup-form-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-form-left h2,
.popup-form-left h3,
.popup-form-left p {
  margin: 0 0 10px;
}

.popup-form-left h2 {
  font-size: 1.5em;
}

.popup-form-left h3 {
  font-size: 1.2em;
}

.popup-form-left p {
  font-size: 1em;
}

.popup-form-left form {
  display: flex;
  flex-direction: column;
}

.popup-form-left label {
  margin-bottom: 5px;
}

.popup-form-left input,
.popup-form-left textarea {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  font-size: 1em;
  margin-bottom: 10px;
  outline: none;
}

.popup-form-left .input-error {
  border-color: red;
}

.popup-form-left .error-message {
  color: red;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.popup-form-left button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
}

.popup-form-left button:hover {
  background-color: darkgray;
}

.popup-form-left .note {
  font-size: 0.8em;
  color: gray;
  margin-top: 10px;
}

.popup-form-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-form-right img {
  width: 100%;
  height: auto;
}

.popup-form-right .close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  align-self: flex-end;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 768px) {
  .popup-form {
    flex-direction: column;
  }

  .popup-form-left,
  .popup-form-right {
    padding: 15px;
  }

  .popup-form-right img {
    display: none;
  }
}
