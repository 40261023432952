.requirements-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.requirements-text {
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.requirements-text h2 {
  font-size: 2rem;
  color: #1b2a3d;
  margin-bottom: 10px;
}

.requirements-text p {
  font-size: 1rem;
  color: #555;
}

.requirements-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
}

.requirement-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: linear-gradient(to left, #fff 50%, #daedea 50%);
  background-size: 200% 100.5%;
  background-position: right;
  transition: background-position 0.5s, border-top 0.5s;
}

.requirement-item:hover {
  border-color: #829693;
  background-position: left;
  text-decoration: none;
}

.requirement-icon {
  flex: 0 0 50px;
}

.requirement-icon {
  width: 50px;
  height: auto;
}

.requirement-content {
  flex: 1;
  padding-left: 20px;
}

.requirement-content h3 {
  font-size: 1.5rem;
  color: #1b2a3d;
  margin: 0 0 10px;
}

.requirement-content p {
  font-size: 1rem;
  color: #555;
}

.requirement-content a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #555;
}

.arrow {
  font-size: 1.5rem;
  color: #555;
}

@media (min-width: 768px) {
  .requirements-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .requirements-text {
    flex: 0 0 40%;
  }

  .requirements-items {
    flex: 0 0 55%;
    margin: 0;
  }

  .requirement-item {
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .requirement-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .requirements-text {
    display: none;
  }

  .requirements-items {
    flex: 0 0 90%;
    margin: 0;
  }

  .requirement-content {
    padding-left: 0;
    padding-top: 10px;
  }

  .requirement-icon {
    align-self: flex-start;
  }
}
