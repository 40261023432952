#footer {
  padding: 20px;
  background-color: #97afbc !important;
}
#footer,
.content-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_text {
  color: #fff;
}

@media screen and (max-width: 310px) {
  #footer,
  .content-footer {
    font-size: small;
  }
}
