body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: scroll; /* Allow vertical scrolling if necessary */
  width: 100%; /* Ensure full width */
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #97afbc !important;
}
