#banner {
  height: 650px;
  /* Smaller default height for smaller devices */
  /* background-image: url("../../../../public/images/banner.png"); */
  /* background-repeat: no-repeat;
  background-size: cover; */
  display: grid;
  justify-content: flex-start;
  align-content: space-between;
  /* background: white; */
  background: #a3c4d3;
  /* background-image: url("https://images.unsplash.com/photo-1499313843378-eebdb187f629?ixid=M3w1NzUyMzF8MHwxfHNlYXJjaHw3fHxCZWF1dHl8ZW58MHwwfHx8MTcxNjQ0NTg4M3ww&ixlib=rb-4.0.3?q=80&w=1920");
  */
  /* filter: brightness(100%) hue-rotate(0deg) saturate(143%) contrast(124%);  */
  /* background-position: 50% 54%; */
}

#banner > img {
  height: inherit;
  width: auto;
  object-fit: cover;
  position: absolute;
}

.banner-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px; /* Adjust based on your image size */
  width: 100%;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.banner-description {
  max-width: 50%;
  font-size: 24px;
  position: absolute;
  right: 20px;
  top: 45%;
  transform: translateY(-50%);
  color: rgb(255 255 255);
  text-align: center;
  background-color: rgba(236, 234, 234, 0.3);
  padding: 45px;
  border-radius: 25px;
}

.banner-title,
.banner-title-buutton {
  width: 100%;
  position: absolute;
  bottom: 49px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(236, 234, 234, 0.3);
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 70px;
  padding-right: 5%;
  font-weight: 400;
  text-align: end;
}
.banner-title-buutton {
  bottom: 165px;
  background: none;
}

.baner-text {
  color: rgb(255, 255, 255) !important;
  text-shadow: 3px 3px 3px black;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  color: transparent;
  /* -webkit-background-clip: text; */
  font-weight: bold;
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

#banner .banner-btn {
  background-color: rgb(255 255 255 / 47%) !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 17px;
  border: unset;
  font-size: medium;
  font-weight: 500;
  width: min-content;
  animation: scale-up 2.5s infinite;
  transition: all 0.3s ease-out;
}

#banner .banner-btn:hover {
  scale: 1.1;
}

.banner-custom-btn {
  opacity: 0;
  position: absolute;
  bottom: -15px;
  padding: 10px 30px;
  border: 1px solid rgb(255, 255, 255);
  text-align: center;
  display: inline-block;
  width: max-content;
  cursor: pointer;
  font-size: large;
  color: rgb(255, 255, 255);
  box-shadow: 3px 3px 3px black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.banner-custom-btn-desktop {
  padding: 10px 30px;
  border: 1px solid rgb(255, 255, 255);
  display: inline-block;
  width: max-content;
  cursor: pointer;
  font-size: large;
  color: rgb(255, 255, 255);
  box-shadow: 3px 3px 3px black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.banner-custom-btn-desktop:hover {
  border: 1px solid rgb(0, 0, 0);
  padding: 13px 33px;
  text-decoration: none;
  color: #000000;
}

.banner-custom-btn:hover {
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  /* background-color: rgb(255, 255, 255); */
  text-decoration: none;
}

.block-banner-text {
  width: max-content;
}

.block-banner-text p {
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  color: transparent;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 22px;
}

@media screen and (max-width: 768px) {
  #banner {
    height: 430px;
    text-align: center;
    /* Original height for larger devices */
  }
  .banner-custom-btn {
    border: 1px solid rgb(255 255 255);
    color: rgb(255 255 255);
  }
  #banner .text h1 {
    font-size: 25px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .banner-title-buutton {
    opacity: 0;
  }

  .block-banner-text p {
    font-size: 12px;
    padding-right: 2px !important;
  }

  .banner-description {
    font-size: large;
  }

  .banner-title {
    font-size: 30px;
  }

  .banner-custom-btn {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 450px) {
  .banner-custom-btn {
    font-size: 13px;
  }

  .banner-description {
    display: none;
  }

  #banner {
    display: flex;
    justify-content: center;
  }

  .banner-title {
    font-size: 25px;
  }

  .block-banner-text {
    width: 100%;
  }

  #banner .text h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 425px) {
  .banner-custom-btn {
    opacity: 1;
  }
  .banner-title {
    text-align: center;
  }
}

@media screen and (max-width: 340px) {
  .banner-custom-btn {
    padding: 10px 20px;
  }
  #banner .text h1 {
    font-size: 20px;
  }
  .banner-title {
    font-size: 21px;
  }
  .block-banner-text p {
    padding-right: 6px !important;
  }
}

@media screen and (max-width: 310px) {
  #banner .text h1 {
    font-size: 18px;
  }
  .block-banner-text p {
    font-size: 9px;
    padding-right: 32px !important;
  }
}
