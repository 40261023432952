/* pentru oferte noi pe viitor */
.offers.offers-cadeau {
  padding-bottom: unset;
}

.offres-block-cadeau {
  text-align: center;
}
.offres-block-cadeau > h1 {
  font-size: 35px;
  color: #111111;
  font-weight: 700;
  margin-bottom: 20px;
}

.offer-section {
  width: 100%;
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  padding: 40px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.offer-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.offer-image img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: floatingImage 3s ease-in-out infinite; /* Animație continuă */
  transition: transform 0.3s ease; /* Tranziție pentru hover */
}

@keyframes floatingImage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.offer-text {
  flex: 1;
}

.offer-text h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.offer-text p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.offer-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.offer-button:hover {
  background-color: #0056b3;
}

.offre-email {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block; /* Asigură că transform funcționează corect */
  animation: floatingText 3s ease-in-out infinite; /* Animația corectă */
}

@keyframes floatingText {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.offre-email:hover {
  color: inherit;
}

@media screen and (max-width: 625px) {
  .offres-block-cadeau > h1 {
    font-size: 25px;
  }
}
